@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "Old Standard TT", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5eff2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.logo {
  width: 66.67%;
}

@media (min-width: 1024px) {
  .logo {
    width: 66.67%;
  }
}

@media (max-width: 1024px) and (orientation: landscape) {
  .logo {
    width: 33.3%;
  }
}

@media (max-width: 1024px) and (orientation: portrait) {
  .logo {
    width: 100%;
  }
}

/* Change pagination bullet color */
.swiper-pagination-bullet {
  background-color: #956c3f !important;
  opacity: 0.5;
}

/* Change active pagination bullet color */
.swiper-pagination-bullet-active {
  background-color: #956c3f !important;
  opacity: 1;
}
.perspective {
  perspective: 1200px;
}

.transform-style-3d {
  transform-style: preserve-3d;
}

.backface-hidden {
  backface-visibility: hidden;
}

.rotate-y-180 {
  transform: rotateY(180deg);
}
